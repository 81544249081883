import './ThankYou.css'

interface IProps {
  closeApp: () => void,
  restartApp: () => void
}
function ThankYou(props: IProps) {
  return (
    <div className="TY-content">
      <h2>Gracias por personalizar con Myecohome.</h2>
      <p>En breve te haremos llegar una estimación de tu proyecto a tu dirección de correo electrónico.</p>
      <p>Construir con Planet.A es sencillo. Nos ocupamos de todo para que disfrutes del proyecto desde el inicio hasta su fin.</p>
      <p>Contáctanos y pregunta por nuestro servicio de asesoría de pre-proyecto totalmente gratuito.</p>
      <br />
      <p>Y recuerda, “There is no Planet B”</p>
      <br />
      <div className="TY-buttons">
        <button className="app-button" onClick={() => props.closeApp()}>
          Volver a la HOME
        </button>
        <button className="app-button" onClick={() => props.restartApp()}>
          Personalizar otro proyecto
        </button>
      </div>
    </div>
  )
}

export default ThankYou