import { Page, Model, SurfaceI, Surface, IExtra, Prototype } from './domain'
import { RoomDistribution } from "./domain/RoomDistribution"
import { getAsset, objectsEqual } from './utils'

export function getPages(): Page[] {
  return [
    { id: "intro", position: 0, header: "" },
    { id: "modelSelector", position: 1, header: "Modelos" },
    { id: "roomNumberSelector", position: 2, header: "Habitaciones" },
    { id: "floorNumberSelector", position: 3, header: "Plantas" },
    { id: "doubleHeight", position: 4, header: "Altura salón" },
    { id: "roomDistribution", position: 5, header: "Plano" },
    { id: "livingRoom", position: 6, header: "Salón" },
    { id: "kitchen", position: 7, header: "Cocina" },
    { id: "bathroom", position: 8, header: "Baño" },
    { id: "facade", position: 9, header: "Fachada" },
    { id: "extras", position: 10, header: "Extras de exterior" },
    { id: "efficiency", position: 11, header: "Extras de eficiencia" },
    { id: "contactForm", position: 12, header: "Datos de contacto" },
    { id: "thankYou", position: 13, header: "" }
  ]
}

export async function getSurfaces(prototype: Prototype, isMobile: boolean): Promise<Surface[]> {
  if (prototype === undefined) return Promise.reject("Tried to call 'getSurfaces' with no 'prototype' parameter");
  const surfaces = await Promise.resolve<SurfaceI[]>([{
    name: "Salón",
    header: "Acabados y pavimentos",
    selectors: [{
      name: "Pavimento",
      type: "select",
      options: [{
        id: "0",
        name: "porcelana clara",
        backgroundImg: getAsset("textures/livingroom_porcelain_light.jpg", isMobile),
        color: "#f5dc98",
        selected: true
      }, {
        id: "1",
        name: "porcelana oscura",
        backgroundImg: getAsset("textures/livingroom_porcelain_dark.jpg", isMobile),
        color: "#806b34"
      }]
    }],
    renders: {
      images: [{
        code: "0",
        name: getAsset("interiors/FORMAT_4-5/livingroom_DH_01.jpg", isMobile)
      }, {
        code: "1",
        name: getAsset("interiors/FORMAT_4-5/livingroom_DH_02.jpg", isMobile)
      }]
    }
  }, {
    name: "Cocina",
    header: "Selecciona sobre nuestra propuesta de acabados de cocina",
    selectors: [{
      name: "Armarios",
      type: "select",
      options: [{
        id: "0",
        name: "gris",
        backgroundImg: getAsset("textures/kitchen_armarios_gris.jpg", isMobile),
        color: "#686868",
        selected: true
      }, {
        id: "1",
        name: "blanco",
        backgroundImg: getAsset("textures/kitchen_armarios_blanco.jpg", isMobile),
        color: "#ffffff"
      }]
    }, {
      name: "Encimera",
      type: "select",
      options: [{
        id: "0",
        name: "negro",
        backgroundImg: getAsset("textures/kitchen_encimera_negro.jpg", isMobile),
        color: "#151515",
        selected: true
      }, {
        id: "1",
        name: "gris",
        backgroundImg: getAsset("textures/kitchen_encimera_gris.jpg", isMobile),
        color: "#c9c3b0"
      }, {
        id: "2",
        name: "blanco",
        backgroundImg: getAsset("textures/kitchen_encimera_blanco.jpg", isMobile),
        color: "#f5f5f5"
      }]
    }],
    renders: {
      images: [{
        code: "00",
        name: getAsset("interiors/FORMAT_4-5/kitchen_00.jpg", isMobile)
      }, {
        code: "01",
        name: getAsset("interiors/FORMAT_4-5/kitchen_01.jpg", isMobile)
      }, {
        code: "02",
        name: getAsset("interiors/FORMAT_4-5/kitchen_02.jpg", isMobile)
      }, {
        code: "10",
        name: getAsset("interiors/FORMAT_4-5/kitchen_10.jpg", isMobile)
      }, {
        code: "11",
        name: getAsset("interiors/FORMAT_4-5/kitchen_11.jpg", isMobile)
      }, {
        code: "12",
        name: getAsset("interiors/FORMAT_4-5/kitchen_12.jpg", isMobile)
      }],
      texts: [{
        code: "",
        text: "Cocina con amplias zonas de almacenaje, equipada con vitrocerámica, horno y campana. Armarios e isla de la\n\
        marca Santos incluidos. Con espacio para nevera de doble puerta"
      }]
    }
  }, {
    name: "Baño",
    header: "",
    selectors: [{
      name: "Armarios",
      type: "select",
      options: [{
        id: "0",
        name: "madera clara",
        backgroundImg: getAsset("textures/bathroom_wood_light.jpg", isMobile),
        color: "#f7dda0",
        selected: true
      }, {
        id: "1",
        name: "madera oscura",
        backgroundImg: getAsset("textures/bathroom_wood_dark.jpg", isMobile),
        color: "#624412"
      }]
    }, {
      name: "Pavimentos",
      type: "select",
      options: [{
        id: "0",
        name: "porcelana clara",
        backgroundImg: getAsset("textures/bathroom_porcelain_light.jpg", isMobile),
        color: "#fce2aa",
        selected: true
      }, {
        id: "1",
        name: "porcelana oscura",
        backgroundImg: getAsset("textures/bathroom_porcelain_dark.jpg", isMobile),
        color: "#b5aba1"
      }]
    }],
    renders: {
      images: [{
        code: "00",
        name: getAsset("interiors/FORMAT_4-5/bathroom_02.jpg", isMobile)
      }, {
        code: "01",
        name: getAsset("interiors/FORMAT_4-5/bathroom_04.jpg", isMobile)
      }, {
        code: "10",
        name: getAsset("interiors/FORMAT_4-5/bathroom_03.jpg", isMobile)
      }, {
        code: "11",
        name: getAsset("interiors/FORMAT_4-5/bathroom_01.jpg", isMobile)
      }],
      texts: [{
        code: "",
        text: "Cuidamos hasta el último detalle.\n\
              En todas nuestras propuestas se incluyen sanitarios, grifería, un mueble\
              de baño y mampara de marcas reconocidas."
      }],
    }
  }, {
    name: "Fachada",
    header: "",
    selectors: [{
      name: "",
      type: "select",
      options: [{
        id: "0",
        name: "madera",
        text: "Madera",
        backgroundImg: getAsset("textures/exterior_madera.jpg", isMobile),
        color: "#c0ab73",
        selected: true
      }, {
        id: "1",
        name: "sate blanco",
        text: "Sate",
        backgroundImg: getAsset("textures/exterior_sate_blanco.png", isMobile),
        color: "#ffffff"
      }, {
        id: "2",
        name: "sate crema",
        text: "Sate",
        backgroundImg: getAsset("textures/exterior_sate_crema.png", isMobile),
        color: "#faeb9e"
      }]
    }],
    renders: {
      images: [{
        code: "0",
        name: prototype.totalFloors() === 1 ?
          getAsset("exteriors/FORMAT_4-5/ext_proto_1_madera_01.jpg", isMobile) :
          getAsset("exteriors/FORMAT_4-5/ext_proto_2_madera_01.jpg", isMobile)
      }, {
        code: "1",
        name: prototype.totalFloors() === 1 ?
          getAsset("exteriors/FORMAT_4-5/ext_proto_1_sate_blanco_01.jpg", isMobile) :
          getAsset("exteriors/FORMAT_4-5/ext_proto_2_sate_blanco_01.jpg", isMobile)
      }, {
        code: "2",
        name: prototype.totalFloors() === 1 ?
          getAsset("exteriors/FORMAT_4-5/ext_proto_1_sate_crema_01.jpg", isMobile) :
          getAsset("exteriors/FORMAT_4-5/ext_proto_2_sate_crema_01.jpg", isMobile)
      }]
    }
  }])
  return surfaces.map((surface) => new Surface(surface.name, surface.header, surface.selectors, surface.renders))
}

export async function getExtras(model: Model) {
  return await Promise.resolve<IExtra[]>(
    model === "adaptive" ?
      [{
        id: "1",
        name: "Barbacoa-Chimenea",
        selected: false
      }, {
        id: "2",
        name: "Piscina",
        selected: false
      }, {
        id: "3",
        name: "Pérgola Esquina",
        group: "1",
        selected: false
      }, {
        id: "4",
        name: "Pérgola Acceso",
        selected: false
      }, {
        id: "5",
        name: "Pérgola Jardín",
        group: "1",
        selected: false
      }]
      :
      [{
        id: "1",
        name: "Barbacoa",
        selected: false
      }, {
        id: "2",
        name: "Piscina",
        selected: false
      }, {
        id: "3",
        name: "Pérgola Jardín",
        selected: false
      }, {
        id: "4",
        name: "Pérgola Acceso",
        selected: false
      }]
  )
}

export async function getEcoEfficiencyExtras() {
  return await Promise.resolve<IExtra[]>(
    [{
      id: "1",
      name: "Aerotermia_",
      description: "Proporciona calor y refrigeración.\n\
                    * Nuestras casas, en zonas de clima mediterráneo apenas requieren de instalación de clima.",
      selected: false
    }, {
      id: "2",
      name: "Recuperador de calor_",
      description: "Ventila y renueva el aire interior de tu vivienda, ahorra energía y evita las alergias por agentes externos del aire.",
      selected: false
    }, {
      id: "3",
      name: "Placas fotovoltaicas_",
      description: "Instalación y equipo completo para hacer tu casa autosuficiente.",
      selected: false
    }, {
      id: "4",
      name: "Almacenamiento energía_",
      description: "Batería para almacenar la energía de tus placas solares y utilizarla a cualquier hora del día.",
      selected: false
    }, {
      id: "5",
      name: "Depósito de recogida de aguas pluviales_",
      description: "10.000 litros para uso en exteriores (riego, limpieza…)",
      selected: false
    }, {
      id: "6",
      name: "Sistema de recogida de aguas grises_",
      description: "Recicla y reutiliza el agua. Ahorra hasta un 40% de consumo diario.",
      selected: false
    }])
}

const prototypes = [
  new Prototype({
    id: "01",
    model: "smart",
    distribution: [{ floor: 0, rooms: 2 }],
    doubleHeight: false,
    builtArea: 100,
    usefulArea: 82,
    description: "2 Hab dobles · 2 baños · Lavadero · Salón · Cocina Americana con Isla (Total: 100 m² const. aprox.)\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Los ventanales y aberturas del salón se pueden ampliar en proyecto, así como en \
                  caso de optar por chimenea reubicarla en diferentes espacios del salón.",
    price: 189000
  }), new Prototype({
    id: "02",
    model: "smart",
    distribution: [{ floor: 0, rooms: 3 }],
    doubleHeight: false,
    builtArea: 115,
    usefulArea: 95,
    description: "3 Hab dobles · 2 baños · Lavadero · Salón · Cocina Americana con Isla (Total: 100 m² const. aprox.)\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Los ventanales y aberturas del salón se pueden ampliar en proyecto, así como en \
                  caso de optar por chimenea reubicarla en diferentes espacios del salón.",
    price: 202000
  }), new Prototype({
    id: "03",
    model: "adaptive",
    distribution: [
      { floor: 0, rooms: 1 },
      { floor: 1, rooms: 0 }
    ],
    doubleHeight: true,
    builtArea: 95,
    usefulArea: 74,
    description: "1 Hab doble· 1 baño· Lavadero· Salón· Cocina Americana con Isla (Total: 95 m² const. aprox.)\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Incluye en proyecto armario de 4 puertas como aprovechamiento en el hueco de la escalera.\n\
                  Los ventanales y aberturas la primera planta se pueden ampliar en proyecto, así como en caso \
                  de optar por chimenea reubicarla en diferentes espacios del salón.\n\
                  Posibilidad con el tiempo de ampliar la vivienda a 2 habitaciones más en el piso superior.",
    price: 212000
  }), new Prototype({
    id: "04",
    model: "adaptive",
    distribution: [
      { floor: 0, rooms: 0 },
      { floor: 1, rooms: 2 }
    ],
    doubleHeight: true,
    builtArea: 102,
    usefulArea: 82,
    description: "2 Hab dobles· 2 baños· Lavadero· Salón doble altura· Cocina Americana con Isla (Total: 102 m² const. aprox.).\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Incluye en proyecto armario de 4 puertas como aprovechamiento en el hueco de la escalera.\n\
                  Los ventanales y aberturas la primera planta se pueden ampliar en proyecto, así como en caso de \
                  optar por chimenea reubicarla en diferentes espacios del salón.\n\
                  Posibilidad con el tiempo de ampliar la vivienda a 1 habitación más en el piso superior.",
    price: 215000
  }), new Prototype({
    id: "05",
    model: "adaptive",
    distribution: [
      { floor: 0, rooms: 0 },
      { floor: 1, rooms: 3 }
    ],
    doubleHeight: false,
    builtArea: 115,
    usefulArea: 92,
    description: "3 Hab dobles· 2 baños· Lavadero· Salón· Cocina Americana con Isla (Total: 115 m² const. aprox.).\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Incluye en proyecto armario de 4 puertas en salón, bajo la zona de escalera.\n\
                  Los ventanales y aberturas la primera planta se pueden ampliar en proyecto, así \
                  como en caso de optar por chimenea reubicarla en diferentes espacios del salón.",
    price: 217000
  }), new Prototype({
    id: "06",
    model: "adaptive",
    distribution: [{ floor: 0, rooms: 1 }],
    doubleHeight: false,
    builtArea: 76,
    usefulArea: 62,
    description: "1 Hab doble· 1 baño· Salón· Cocina Americana con Isla (Total:76 m² const. aprox.)\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Los ventanales y aberturas del salón se pueden ampliar en proyecto, así como en \
                  caso de optar por chimenea reubicarla en diferentes espacios del salón.\n\
                  La habitación de 14m² útiles, con zona de almacenaje amplia multifuncional.",
    price: 165000
  }), new Prototype({
    id: "07",
    model: "adaptive",
    distribution: [
      { floor: 0, rooms: 1 },
      { floor: 1, rooms: 1 }
    ],
    doubleHeight: true,
    builtArea: 120,
    usefulArea: 103,
    description: "2 Hab dobles· 2 baños· Lavadero· Estudio· Salón doble altura· Cocina Americana con Isla· \
                  Distribuidor zona Chillout (Total: 120 m² const. aprox.).\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Incluye en proyecto armario de 4 puertas en salón, bajo la zona de escalera.\n\
                  Los ventanales y aberturas la primera planta se pueden ampliar en proyecto, así \
                  como en caso de optar por chimenea reubicarla en diferentes espacios del salón.",
    price: 233000
  }), new Prototype({
    id: "08",
    model: "adaptive",
    distribution: [
      { floor: 0, rooms: 0 },
      { floor: 1, rooms: 3 }
    ],
    doubleHeight: true,
    builtArea: 127,
    usefulArea: 110,
    description: "3 Hab dobles· 2 baños· Lavadero· Estudio· Salón doble altura· Cocina Americana con Isla· \
                  Distribuidor zona Chillout (Total: 127 m² const. aprox.).\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Incluye en proyecto armario de 4 puertas en salón, bajo la zona de escalera.\n\
                  Los ventanales y aberturas la primera planta se pueden ampliar en proyecto, así \
                  como en caso de optar por chimenea reubicarla en diferentes espacios del salón.",
    price: 240000
  }), new Prototype({
    id: "09",
    model: "adaptive",
    distribution: [
      { floor: 0, rooms: 0 },
      { floor: 1, rooms: 4 }
    ],
    doubleHeight: true,
    builtArea: 135,
    usefulArea: 117,
    description: "4 Hab dobles· 2 baños· Lavadero· Estudio· Salón doble altura· Cocina Americana con Isla· \
                  Distribuidor zona Chillout (Total: 135 m² const. aprox.).\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Incluye en proyecto armario de 4 puertas en salón, bajo la zona de escalera.\n\
                  Los ventanales y aberturas la primera planta se pueden ampliar en proyecto, así \
                  como en caso de optar por chimenea reubicarla en diferentes espacios del salón.",
    price: 244000
  }), new Prototype({
    id: "10",
    model: "adaptive",
    distribution: [
      { floor: 0, rooms: 0 },
      { floor: 1, rooms: 5 }
    ],
    doubleHeight: false,
    builtArea: 150,
    usefulArea: 121,
    description: "5 Hab dobles (1 Hab reconvertible a 2do salon en planta superior) ·2 baños· Lavadero· \
                  Salón· Cocina Americana con Isla  (Total: 150 m² const. aprox.).\n\
                  Diseño versátil que permite ofrecer una planta primera con posibilidad de un 2do salón.\n\
                  Incluye en proyecto armario de 4 puertas en salón, bajo la zona de escalera.\n\
                  Los ventanales y aberturas la primera planta se pueden ampliar en proyecto, así \
                  como en caso de optar por chimenea reubicarla en diferentes espacios del salón.\n\
                  Todos nuestros diseños estan pensados para adaptarse a cualquier parcela.",
    price: 257000
  }), new Prototype({
    id: "11",
    model: "smart",
    distribution: [
      { floor: 0, rooms: 4 }
    ],
    doubleHeight: false,
    builtArea: 150,
    usefulArea: 121,
    description: "4 Hab · 2 baños · Lavadero · Salón · Cocina Americana con Isla (Total: 150 m2 const. aprox.)\n\
                  Diseño versátil que se puede hacer rotar para adaptarlo a cualquier parcela.\n\
                  Los ventanales y aberturas del salón se pueden ampliar en proyecto, así como en caso de\
                  optar por chimenea reubicarla en diferentes espacios del salón.",
    price: 255000
  })
]

interface getPrototypesParams {
  model?: Model,
  floors?: number,
  rooms?: number,
  doubleHeight?: boolean,
  distribution?: RoomDistribution
}

export async function getPrototypes(params?: getPrototypesParams) {
  return await Promise.resolve<Prototype[]>(
    !params ? prototypes : prototypes.filter((p) =>
      (params.model === undefined || p.model == params.model) &&
      (params.floors === undefined || p.totalFloors() == params.floors) &&
      (params.rooms === undefined || p.totalRooms() == params.rooms) &&
      (params.doubleHeight === undefined || p.doubleHeight == params.doubleHeight) &&
      (params.distribution === undefined || p.distribution == params.distribution)
    )
  )
}

function distributionEquals(distributionA: RoomDistribution, distributionB: RoomDistribution): boolean {
  return distributionA.reduce<boolean>((equal, ROFA) => {
    if (equal) {
      const floor = ROFA.floor;
      const ROFB = distributionB.find((ROFB) => ROFB.floor === floor);
      return objectsEqual(ROFA, ROFB);
    } else {
      return false;
    }
  }, true);
}

export function filterPrototypes(prototypes: Prototype[], params?: getPrototypesParams) {
  function distributionEquals(distributionA: RoomDistribution, distributionB: RoomDistribution): boolean {
    return distributionA.reduce<boolean>((equal, ROFA) => {
      if (equal) {
        const floor = ROFA.floor;
        const ROFB = distributionB.find((ROFB) => ROFB.floor === floor);
        return ROFB !== undefined && objectsEqual(ROFA, ROFB);
      } else {
        return false;
      }
    }, true);
  }

  return (
    !params ? prototypes : prototypes.filter((p) =>
      (params.model === undefined || p.model == params.model) &&
      (params.floors === undefined || p.totalFloors() == params.floors) &&
      (params.rooms === undefined || p.totalRooms() == params.rooms) &&
      (params.doubleHeight === undefined || p.doubleHeight == params.doubleHeight) &&
      (params.distribution === undefined || distributionEquals(p.distribution, params.distribution))
    )
  )
}

interface getDistributionsParams {
  model?: Model,
  floors?: number,
  rooms?: number,
  doubleHeight?: boolean
}

export function getDistributions(prototypes: Prototype[], params: getDistributionsParams) {
  return prototypes
    .filter((p) =>
      (params.model === undefined || p.model == params.model) &&
      (params.floors === undefined || p.totalFloors() == params.floors) &&
      (params.rooms === undefined || p.totalRooms() == params.rooms) &&
      (params.doubleHeight === undefined || p.doubleHeight == params.doubleHeight)
    )
    .map((p) => p.distribution)
}