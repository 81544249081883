import { Prototype } from '../../domain'
import NavButtons from '../NavButtons';

import './RoomsSelector.css'

interface IProps {
  prototypes: Prototype[],
  rooms: number,
  setRooms: (rooms: number) => void,
  handlePrevScreen: () => void,
  handleNextScreen: () => void
}

function RoomsSelector(props: IProps) {
  /** Filter all prototypes with the same number of total rooms selecting only the cheapest one */
  const filterCheapest = (prototypes: Prototype[]) => {
    return prototypes
      .flatMap((p) => {
        const existsCheaper = prototypes.find(p2 => p2.totalRooms() === p.totalRooms() && p2.price < p.price);
        return existsCheaper ? [] : p;
      })
      .sort((a, b) => a.totalRooms() < b.totalRooms() ? -1 : 1);
  }
  // Make sure a valid value is set
  const possibleRooms = filterCheapest(props.prototypes).map(p => p.totalRooms());
  if (possibleRooms.find(n => n === props.rooms) === undefined) {
    props.setRooms(possibleRooms[0]);
  }
  return (
    <div className="RS-content">
      <h1>Selecciona número de habitaciones</h1>
      <div className="RS-selector">
        {filterCheapest(props.prototypes).map((p, i) =>
          <div key={i} className="RS-option-wrapper">
            <button
              id={`rooms_${p.totalRooms().toString()}`}
              key={i}
              className={`RS-room-button ${props.rooms === p.totalRooms() && "RS-room-button-selected"}`}
              onClick={() => props.setRooms(p.totalRooms())}>
              {p.totalRooms()}
            </button>
            <label>{`desde ${(Math.round(p.price / 1000) * 1000).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`}</label>
          </div>
        )}
      </div>
      <br></br>
      <p className="RS-disclaimer">{
        "* Estos precios son orientativos y no incluyen honorarios técnicos,\
        por lo que no constituyen oferta ni obligación contractual alguna."
      }</p>
      <NavButtons handlePrevScreen={props.handlePrevScreen} handleNextScreen={props.handleNextScreen} />
    </div>
  )
}

export default RoomsSelector