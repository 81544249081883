import { useEffect, useState } from 'react';
// external sources
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Line } from 'rc-progress';

import Intro from './components/pages/Intro';
import ModelSelector from './components/pages/ModelSelector';
import RoomsSelector from './components/pages/RoomsSelector';
import FloorsSelector from './components/pages/FloorsSelector';
import DoubleHeight from './components/pages/DoubleHeight';
import SurfaceSelector from './components/pages/SurfaceSelector';
import Extras from './components/pages/Extras';
import DistributionSelector from './components/pages/DistributionSelector';
import ContactForm from './components/pages/ContactForm';
import ThankYou from './components/pages/ThankYou';
import { getSurfaces, getPages, getDistributions, getExtras, getPrototypes, filterPrototypes, getEcoEfficiencyExtras } from './database';
import { Page, Model, Surface, IExtra, Prototype } from './domain';
import { RoomDistribution } from './domain/RoomDistribution';

import './App.css';
import EcoEfficiency from './components/pages/EcoEfficiency';
import { useMediaQuery } from 'react-responsive';
import { WEBSITE_URL } from './env';

const pages = getPages();

function App() {
  const [page, setPage] = useState<Page>(pages[0]);
  const [model, setModel] = useState<Model>("smart");
  const [rooms, setRooms] = useState<number>(3);
  const [floors, setFloors] = useState<number>(1);
  const [doubleHeight, setDoubleHeight] = useState<boolean>(false);
  const [distribution, setDistribution] = useState<RoomDistribution>([{ floor: 0, rooms: 3 }]);
  const [surfaces, setSurfaces] = useState<Surface[]>([]);
  const [extras, setExtras] = useState<IExtra[]>([]);
  const [ecoExtras, setEcoExtras] = useState<IExtra[]>([]);

  const [prototypes, setPrototypes] = useState<Prototype[]>([]);

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  useEffect(() => {
    getPrototypes()
      .then(prototypes => {
        setPrototypes(prototypes);
        getSurfaces(prototypes[0], isMobile)
          .then(surfaces => setSurfaces(surfaces));
      });
  }, []);

  useEffect(() => {
    getExtras(model)
      .then(extras => setExtras(extras));
    getEcoEfficiencyExtras()
      .then(ecoExtras => setEcoExtras(ecoExtras));
  }, [model]);

  useEffect(() => {
    const floorOptions = filterPrototypes(prototypes, { model: model, rooms: rooms }).map(p => p.totalFloors())
      .filter((p, i, arr) => arr.indexOf(p) === i)  //remove duplicates
      .sort();
    setFloors(floorOptions[0]);
  }, [rooms]);

  useEffect(() => {
    const doubleHeightOptions = filterPrototypes(prototypes, { model: model, rooms: rooms, floors: floors }).map(p => p.doubleHeight)
      .filter((p, i, arr) => arr.indexOf(p) === i);  //remove duplicates
    if (doubleHeightOptions.length > 0) {
      setDoubleHeight(doubleHeightOptions[0]);
    }
  }, [floors]);

  useEffect(() => {
    setDistribution(getDistributions(prototypes, { model: model, rooms: rooms, floors: floors })[0]);
  }, [model, floors, rooms]);

  useEffect(() => {
    const filteredPrototypes = filterPrototypes(prototypes, { model: model, distribution: distribution });
    const prototype = filteredPrototypes.length > 0 ? filteredPrototypes[0] : undefined;
    if (prototype) {
      getSurfaces(prototype, isMobile)
        .then(surfaces => setSurfaces(surfaces));
    } else {
      setSurfaces([]);
    }
  }, [distribution]);

  // window.addEventListener('onbeforeunload', /* send to server which screen the user was when he closed the app */);

  function handleNextScreen() {
    let nextScreen = pages[page.position + 1];
    while (nextScreen.position < pages.length && !isValidScreen(nextScreen)) {
      nextScreen = pages[nextScreen.position + 1];
    }
    if (nextScreen.position < pages.length) {
      setPage(nextScreen);
    } else {
      toast.error("La aplicación no tiene más páginas!");
    }
  }

  function handlePrevScreen() {
    let prevScreen = pages[page.position - 1];
    while (prevScreen.position >= 0 && !isValidScreen(prevScreen)) {
      prevScreen = pages[prevScreen.position - 1];
    }
    if (prevScreen.position >= 0) {
      setPage(prevScreen);
    } else {
      toast.error("La aplicación no tiene más páginas!");
    }
  }

  function isValidScreen(screen: Page) {
    switch (screen.id) {
      case "floorNumberSelector": {
        const floorOptions = filterPrototypes(prototypes, { model: model, rooms: rooms }).map(p => p.totalFloors())
          .filter((p, i, arr) => arr.indexOf(p) === i)  //remove duplicates
          .sort();
        if (floorOptions.length > 1) {
          return true;
        } else {
          setFloors(floorOptions[0]);
          return false;
        }
      }
      case "doubleHeight": {
        const doubleHeightOptions = filterPrototypes(prototypes, { model: model, rooms: rooms, floors: floors }).map(p => p.doubleHeight)
          .filter((p, i, arr) => arr.indexOf(p) === i);  //remove duplicates
        if (doubleHeightOptions.length > 1) {
          return true;
        } else {
          setDoubleHeight(doubleHeightOptions[0]);
          return false;
        }
      }
      default:
        return true;
    }
  }

  function closeApp(withConfirm = false) {
    let doClose = true;
    if (withConfirm && page.position > 1) {
      doClose = window.confirm("Seguro que quieres volver a la Home? Perderás el progreso realizado en la aplicación de personalización.");
    }
    // we keep the existing query params when transitioning to the website url
    const queryParamsStr = Array.from(new URLSearchParams(window.location.search).entries())
      .reduce((acc, [k, v], i) =>
        i === 0 ? `${k}=${v}` : acc + `&${k}=${v}`
        , "");
    const website_url = queryParamsStr !== "" ? `${WEBSITE_URL}?${queryParamsStr}` : WEBSITE_URL;
    doClose && window.location.replace(website_url);
  }

  function renderScreen(screen: Page): JSX.Element {
    switch (screen.id) {
      case "intro":
        return (
          <Intro
            handleNextScreen={() => handleNextScreen()}
          />
        )
      case "modelSelector":
        return (
          <ModelSelector
            model={model}
            setModel={(model: Model) => setModel(model)}
            handlePrevScreen={() => handlePrevScreen()}
            handleNextScreen={() => handleNextScreen()}
          />
        )
      case "roomNumberSelector":
        return (
          <RoomsSelector
            prototypes={filterPrototypes(prototypes, { model: model })}
            rooms={rooms}
            setRooms={(rooms: number) => setRooms(rooms)}
            handlePrevScreen={() => handlePrevScreen()}
            handleNextScreen={() => handleNextScreen()}
          />
        )
      case "floorNumberSelector": {
        const floorOptions = filterPrototypes(prototypes, { model: model, rooms: rooms }).map(p => p.totalFloors())
          .filter((p, i, arr) => arr.indexOf(p) === i)  //remove duplicates
          .sort();
        return <FloorsSelector
          options={floorOptions}
          floors={floors}
          setFloors={(floors: number) => setFloors(floors)}
          handlePrevScreen={() => handlePrevScreen()}
          handleNextScreen={() => handleNextScreen()}
        />
      }
      case "doubleHeight":
        return (
          <DoubleHeight
            doubleHeight={doubleHeight}
            setDoubleHeight={(doubleHeight: boolean) => setDoubleHeight(doubleHeight)}
            handlePrevScreen={() => handlePrevScreen()}
            handleNextScreen={() => handleNextScreen()}
          />
        )
      case "roomDistribution": {
        const filteredPrototypes = filterPrototypes(prototypes, { model: model, rooms: rooms, floors: floors, doubleHeight: doubleHeight });
        return filteredPrototypes.length !== 0 ?
          <DistributionSelector
            prototypes={filteredPrototypes}
            setDistribution={(distribution: RoomDistribution) => setDistribution(distribution)}
            handlePrevScreen={() => handlePrevScreen()}
            handleNextScreen={() => handleNextScreen()}
          /> :
          <>`No se ha encontrado ningún prototipo que cumpla: [model={model},rooms={rooms},floors={floors},doubleHeight={doubleHeight ? "true" : "false"}]`</>;
      }
      case "livingRoom":
        return (
          <SurfaceSelector
            surface={surfaces[0]}
            setSurface={(surface: Surface) => {
              const newSurfaces = surfaces.slice();
              newSurfaces[0] = surface;
              setSurfaces(newSurfaces);
            }}
            handlePrevScreen={() => handlePrevScreen()}
            handleNextScreen={() => handleNextScreen()}
          />
        )
      case "kitchen":
        return (
          <SurfaceSelector
            surface={surfaces[1]}
            setSurface={(surface: Surface) => {
              const newSurfaces = surfaces.slice()
              newSurfaces[1] = surface
              setSurfaces(newSurfaces)
            }}
            handlePrevScreen={() => handlePrevScreen()}
            handleNextScreen={() => handleNextScreen()}
          />
        )
      case "bathroom":
        return (
          <SurfaceSelector
            surface={surfaces[2]}
            setSurface={(surface: Surface) => {
              const newSurfaces = surfaces.slice()
              newSurfaces[2] = surface
              setSurfaces(newSurfaces)
            }}
            handlePrevScreen={() => handlePrevScreen()}
            handleNextScreen={() => handleNextScreen()}
          />
        )
      case "facade":
        return (
          <SurfaceSelector
            surface={surfaces[3]}
            setSurface={(surface: Surface) => {
              const newSurfaces = surfaces.slice()
              newSurfaces[3] = surface
              setSurfaces(newSurfaces)
            }}
            handlePrevScreen={() => handlePrevScreen()}
            handleNextScreen={() => handleNextScreen()}
          />
        )
      case "extras":
        return (<Extras
          model={model}
          extras={extras}
          setExtras={(extras: IExtra[]) => setExtras(extras)}
          handlePrevScreen={() => handlePrevScreen()}
          handleNextScreen={() => handleNextScreen()}
        />)
      case "efficiency":
        return (<EcoEfficiency
          ecoExtras={ecoExtras}
          setEcoExtras={(ecoExtras: IExtra[]) => setEcoExtras(ecoExtras)}
          handlePrevScreen={() => handlePrevScreen()}
          handleNextScreen={() => handleNextScreen()}
        />)
      case "contactForm": {
        const prototype = filterPrototypes(prototypes, { model: model, distribution: distribution, doubleHeight: doubleHeight })[0];
        return (
          <ContactForm
            prototype={prototype}
            surfaces={surfaces}
            extras={extras}
            ecoExtras={ecoExtras}
            handleNextScreen={() => handleNextScreen()}
          />
        )
      }
      case "thankYou":
        return (
          <ThankYou
            closeApp={() => closeApp()}
            restartApp={() => setPage(pages[0])}
          />
        )
      default:
        return (<p>Invalid screen id: {screen.header}</p>)
    }
  }

  return (
    <div className="app-container">
      <header className="app-header">
        <div className="header-content">
          <button className="logo-button" onClick={() => closeApp(true)}>
            <img className="logo-img" src="assets/logo/logo-gris_horizontal.png" alt={"logo"} />
          </button>
          <h1 className="page-title">
            {page.header}
          </h1>
        </div>
        {(page.position > 0 || isMobile) && <Line percent={(page.position / pages.length) * 100} strokeWidth={0.35} strokeColor="var(--color-dark-gray)"></Line>}
      </header>
      <div className={`app-content ${page.id !== "extras" && "bola-background"}`}>
        {renderScreen(page)}
      </div>
      <ToastContainer position="top-left" theme="colored" />
    </div>
  )
}

export default App;