import ImageSelector, { Selector } from '../ImageSelector'
import { Model } from '../../domain'

import './ModelSelector.css'
import { useMediaQuery } from 'react-responsive'
import { getAsset } from '../../utils'
import NavButtons from '../NavButtons'

interface IProps {
	handlePrevScreen: () => void
	handleNextScreen: () => void
	model: Model
	setModel: (model: Model) => void
}

function ModelSelector(props: IProps) {
	const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
	const selectors: Selector[] = [{
		id: "smart",
		title: "ECO·ONE_",
		subtitle: "1 Planta · 2 a 4 Hab",
		images: [getAsset("exteriors/FORMAT_4-5/ext_proto_1_sate_blanco_01.jpg", isMobile)],
		text: "Distribución en una sola planta, tendrás todo a tu alcance.\
					Define tu modelo y escoge entre 2 y 4 habitaciones dobles.\
					Espacios pensados de forma inteligente para ofrecer el\
					máximo confort y eficiencia.\n\
					Diseño, sencillez, elegancia y optimización del espacio\
					definen nuestra Eco-One.",
		selected: props.model === "smart"
	}, {
		id: "adaptive",
		title: "ECO·ADAPTIVE_",
		subtitle: "2 Plantas · 1 a 5 Hab",
		images: [
			getAsset("exteriors/FORMAT_16-9/ext_proto_2_sate_blanco_01.jpg", isMobile),
			getAsset("exteriors/FORMAT_16-9/ext_proto_2_sate_blanco_02.jpg", isMobile)
		],
		text:
			"Distribución en 2 plantas que podrás personalizar según tus\
			necesidades.\n\
			Diseño inteligente con espacios multifuncionales que podrás\
			adaptar y readaptar a futuro. Empieza con una casa con pocas\
			habitaciones, y hazla crecer hasta 5 en un futuro.\n\
			Elige adaptive y descubre todo su potencial.",
		selected: props.model === "adaptive"
	}]

	return (
		<div className="MS-content">
			<ImageSelector
				options={selectors}
				onSelect={(modelStr: string) => {
					const model = modelStr as Model;
					props.setModel(model);
				}}
				class="MS-selector"
				imgStyle="cover"
			/>
			<NavButtons handlePrevScreen={props.handlePrevScreen} handleNextScreen={props.handleNextScreen} />
		</div>
	);
}

export default ModelSelector