export interface IProps {
  label?: string,
  placeholder?: string,
  items: string[] | { value: any, text: string, selected?: boolean }[],
  onChange: (value: string) => void
}
export function DropDownSelect(props: IProps) {
  function label(label?: string) {
    if (label === undefined) {
      return <></>
    } else {
      return <label style={{ paddingRight: "10px" }}>{props.label}</label>
    }
  }
  function placeholder(placeholder?: string) {
    if (placeholder === undefined) {
      return <></>
    } else {
      return <option disabled selected>{placeholder}</option>
    }
  }
  return <div>
    {label(props.label)}
    <select onChange={(event: React.ChangeEvent<HTMLSelectElement>) => props.onChange(event.target.value)}>
      {placeholder(props.placeholder)}
      {props.items.map(it => {
        if (typeof it === "string") {
          return <option key={it} value={it}>{it}</option>
        } else {
          return <option key={it.value} value={it.value} selected={it.selected || false}>{it.text}</option>
        }
      })}
    </select>
  </div>
}

export default DropDownSelect