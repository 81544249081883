import './RadioButton.css'

interface IProps {
  selected: boolean,
  handleChange: () => void,
  label?: string,
  text?: string,
  buttonColor?: string,                     //default: --color-sage
  buttonBackgroundImg?: string,             //default: none
  textLocation?: "text-top" | "text-right"  //default: top
}

function RadioButton(props: IProps) {
  return (
    <div className={`radio-button-wrapper ${props.textLocation}`}>
      <div className="flex-column">
        {props.label && <p className="label">{props.label}</p>}
        {props.text && <p className="text">{props.text}</p>}
      </div>
      <div className={`oval ${props.textLocation} ${props.selected && "oval-selected"}`}
        style={
          props.buttonBackgroundImg ?
            { backgroundImage: `url('${props.buttonBackgroundImg}')` }
            :
            { background: props.buttonColor || "var(--color-sage)" }
        }
        onClick={() => props.handleChange()}
      />
    </div>)
}

export default RadioButton