import { IExtra } from '../../domain'
import Checkbox from '../Checkbox'
import NavButtons from '../NavButtons'
import './EcoEfficiency.css'

interface IProps {
  ecoExtras: IExtra[],
  setEcoExtras: (ecoExtras: IExtra[]) => void,
  handlePrevScreen: () => void,
  handleNextScreen: () => void
}

function Extras(props: IProps) {
  return (
    <div className="EE-content">
      <div className="horizontal-separator-s" />
      <div className="EE-checkboxes">
        {props.ecoExtras.map((e, i) =>
          <Checkbox
            key={i}
            label={e.name}
            description={e.description}
            selected={e.selected}
            handleChange={() => props.setEcoExtras([...props.ecoExtras.slice(0, i), { ...e, selected: !e.selected }, ...props.ecoExtras.slice(i + 1, props.ecoExtras.length)])}></Checkbox>
        )}
      </div>
      <div className="horizontal-separator-s" />
      <NavButtons handlePrevScreen={props.handlePrevScreen} handleNextScreen={props.handleNextScreen} />
    </div>
  )
}

export default Extras