import { useState } from 'react';
import { Prototype } from '../../domain';
import { RoomDistribution } from "../../domain/RoomDistribution";
import { getAsset, objectsEqual } from '../../utils';

import './DistributionSelector.css';
import Checkbox from '../Checkbox';
import ImageSelector from '../ImageSelector';
import NavButtons from '../NavButtons';

interface IProps {
  prototypes: Prototype[];
  setDistribution: (distribution: RoomDistribution) => void;
  handlePrevScreen: () => void;
  handleNextScreen: () => void;
}

function DistributionSelector(props: IProps) {
  const [prototype, setPrototype] = useState(props.prototypes[0]);

  const prototypeImgId = (prototype: Prototype, floor: number): string => {
    const id = prototype.id;
    const f = `_${floor}`;
    return id + f;
  }

  return (
    <div className="DS-content">
      <div className="horizontal-separator-s" />
      <ImageSelector
        key={prototype.id}
        options={prototype.distribution.map(d => {
          return {
            id: d.floor.toString(),
            title: d.floor === 0 ? "Planta baja" : "Planta primera",
            images: [
              getAsset(`blueprints/${prototypeImgId(prototype, d.floor)}.png`)
            ],
            text: prototype.description
          }
        })}
        onSelect={() => { "do nothing" }}
        class="DS-selector"
        JSX={props.prototypes.length > 1 ?
          <Checkbox
            id="room_on_first_floor"
            yesNo={true}
            label="¿Quieres una habitación en planta baja?"
            selected={prototype.distribution.find((d) => d.floor === 0)?.rooms === 1}
            handleChange={() => {
              const newPrototype = props.prototypes.find((p) => !objectsEqual(p, prototype))! //get the other prototype (there should only be 2)
              setPrototype(newPrototype);
              props.setDistribution(newPrototype.distribution);
            }}
            className="DS-checkbox"
          /> : undefined}
      />
      <div className="horizontal-separator-s" />
      <NavButtons handlePrevScreen={props.handlePrevScreen} handleNextScreen={props.handleNextScreen} />
      <div className="horizontal-separator-s" />
    </div>
  )
}

export default DistributionSelector