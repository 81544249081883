import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getUtms } from '../../domain/UTM';
import { IExtra, Surface, Prototype } from '../../domain';
import { SERVER_URL } from '../../env';
import Checkbox from '../Checkbox';
import DropDownSelect from '../DropDownSelect';

import "./ContactForm.css";
import { NewHomeRequest } from '../../api/NewHomeRequest';

interface IProps {
  prototype: Prototype,
  surfaces: Surface[],
  extras: IExtra[],
  ecoExtras: IExtra[],
  handleNextScreen: () => void;
}
function ContactForm(props: IProps) {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lot, setLot] = useState({
    has: false,
    isLookingFor: false,
    locality: "",
    address: "",
    zone: ""
  });
  const [allowsDataTreatment, setAllowsDataTreatment] = useState(false);
  const [allowsNews, setAllowsNews] = useState(false);

  const [errors, setErrors] = useState(new Map<string, string[]>());
  const [showErrors, setShowErrors] = useState(false);

  const buildRequest = () => {
    const newHomeRequest: NewHomeRequest = {
      client: {
        name: name,
        surname: surname,
        email: email,
        phone: phone,
        lot: lot,
        allowsNews: allowsNews,
      },
      prototype: props.prototype,
      surfaces: props.surfaces
        .map(i => {
          return {
            name: i.name,
            selectors: i.selectors.map((s) => {
              return {
                name: s.name,
                option: s.options.find(o => o.selected)!.name
              }
            }
            )
          }
        }),
      extras: props.extras.filter(e => e.selected),
      ecoExtras: props.ecoExtras.filter(e => e.selected),
      UTM: getUtms(window.location.search)
    };
    return newHomeRequest;
  }

  const handleSubmit = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    if (errors.size > 0) {
      setShowErrors(true);
      toast.error("Algunos campos son incorrectos.");
    } else if (!allowsDataTreatment) {
      toast.info("Por favor, autoriza el tratamiento de los datos introducidos para proseguir.");
    } else {
      fetch(`${SERVER_URL}/api/newHome`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(buildRequest())
      }).then(
        (res) => {
          if (res.status === 200) {
            props.handleNextScreen();
          } else {
            toast.error("Lo sentimos, ha habido un error enviando los datos.");
            console.error(res.body);
          }
        },
        (err) => {
          toast.error("Error accediendo al servidor.");
          console.error(err);
        }
      );
    }
  }

  const renderLotFields = () => {
    if (lot.has) {
      return <>
        <FormInput
          id="locality"
          placeholder="localidad del solar"
          onChange={(v: string) => setLot({ ...lot, locality: v })}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          required
        />
        <div className="vertical-separator-m" />
        <FormInput
          id="address"
          placeholder="dirección del solar"
          onChange={(v: string) => setLot({ ...lot, address: v })}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          required
        />
      </>
    } else if (lot.isLookingFor) {
      return <FormInput
        id="zone"
        placeholder="¿En qué zona?"
        onChange={(v: string) => setLot({ ...lot, zone: v })}
        errors={errors}
        setErrors={setErrors}
        showErrors={showErrors}
      />
    }
  }

  return (
    <div className="CF-content">
      <form className="CF-form" onSubmit={handleSubmit}>
        <div className="CF-inputs-wrapper">
          <p>Introduce tus datos y te haremos llegar la memoria de calidades y presupuesto a tu correo electrónico sin compromiso:</p>
          <div className="CF-inputs">
            <FormInput
              id="name"
              placeholder="nombre"
              onChange={setName}
              errors={errors}
              setErrors={setErrors}
              showErrors={showErrors}
              required
            />
            <FormInput
              id="surname"
              placeholder="apellidos"
              onChange={setSurname}
              errors={errors}
              setErrors={setErrors}
              showErrors={showErrors}
              required
            />
            <FormInput
              id="email"
              placeholder="email"
              onChange={setEmail}
              validate={(v) => {
                const errs = []
                !new RegExp(/^.+@.+\..+$/).test(v) && errs.push("Dirección de email inválida")
                return errs
              }}
              errors={errors}
              setErrors={setErrors}
              showErrors={showErrors}
              required
            />
            <FormInput
              id="phone"
              placeholder="teléfono"
              onChange={setPhone}
              errors={errors}
              setErrors={setErrors}
              showErrors={showErrors}
            />
          </div>
          <p>La ubicación y características del <b>solar</b> son importantes para el desarrollo del proyecto,
            marca tu situación y lo tendremos en cuenta en el presupuesto:</p>
          <div className="CF-select-wrapper">
            <DropDownSelect
              label="¿Cuál es mi situación?"
              items={["Sólo quiero información", "Estoy buscando solar", "Ya tengo solar"]}
              onChange={(value: string) => {
                switch (value) {
                  case "Sólo quiero información":
                    setLot({ ...lot, has: false, isLookingFor: false })
                    break
                  case "Estoy buscando solar":
                    setLot({ ...lot, has: false, isLookingFor: true })
                    break
                  case "Ya tengo solar":
                    setLot({ ...lot, has: true, isLookingFor: false })
                    break
                  default:
                    alert(`Invalid select value [${value}]`)
                }
              }
              }
            />
            <div className="vertical-separator-m" />
            {renderLotFields()}
          </div>
        </div>
        <div className="horizontal-separator-s" />
        <div className="CF-checkboxes-wrapper">
          <Checkbox
            id="auth_checkbox"
            label="Autorizo al tratamiento de mis datos para poder gestionar esta consulta, y
                  declaro haber leído y estar conforme con la política de privacidad de Planet.A eco homes S.L."
            labelClass="CF-checkbox-label"
            buttonClass="CF-checkbox-button"
            selected={allowsDataTreatment}
            handleChange={() => setAllowsDataTreatment(!allowsDataTreatment)}
          />
          <Checkbox
            id="info_checkbox"
            label="Estoy interesado en recibir información y novedades sobre Planet.A eco homes."
            labelClass="CF-checkbox-label"
            buttonClass="CF-checkbox-button"
            selected={allowsNews}
            handleChange={() => setAllowsNews(!allowsNews)}
          />
        </div>
        <div className="horizontal-separator-s" />
        <div className="CF-button-wrapper">
          <p>Sólo te enviaremos la información que necesites y para la que nos hayas autorizado.
            Puedes ejercer tu derecho de cancelación y revocar tu consentimiento enviando un email a <a href="mailto:info@planetaecohomes.com">info@planetaecohomes.com</a>.
            Para más información consulta nuestra <a href="https://planetaecohomes.com/politica-de-privacidad/" target="_blank" rel="noopener noreferrer">política de privacidad</a>.</p>
          <div><input className="app-button CF-send-button" type="submit" value="Obtener presupuesto" /></div>
        </div>
        <div className="horizontal-separator-s" />
      </form>
    </div>
  )
}

interface FormInputProps {
  id: string
  placeholder: string
  onChange: (...args: any[]) => void,
  errors: Map<string, string[]>
  setErrors: React.Dispatch<React.SetStateAction<Map<string, string[]>>>
  showErrors?: boolean
  validate?: (value: string) => string[]
  required?: boolean
}
/**
 * This component renders an input with error handling.
 * 
 * It expects an error map from its parent component and its setter function. It will store the errors in the 'errors[id]' keyspace.
 */
function FormInput(props: FormInputProps) {
  const requiredMsg = "Este campo no puede estar vacío"
  const setErrors = (errors: string[]) => {
    props.setErrors(prevErrors => {
      const newErrors = new Map(prevErrors)
      errors.length > 0 && newErrors.set(props.id, errors)
      errors.length === 0 && newErrors.delete(props.id)
      return newErrors
    })
  }
  const showFirstError = (errors?: string[]) => {
    const first = errors?.at(0)
    return first && props.showErrors ? <p key={first} className="CF-error-text">{first}</p> : <></>
  }

  useEffect(() => {
    props.required && setErrors([requiredMsg])
    return () => { props.errors && setErrors([]) }
  }, [])

  return (<div className="form-input-wrapper">
    <input
      id={props.id}
      className="form-input"
      placeholder={props.required ? `${props.placeholder} *` : props.placeholder}
      onChange={ev => {
        const value = ev.target.value
        props.onChange(value)
        let errs = props.required && value.length === 0 ? [requiredMsg] : []
        errs = props.validate ? errs.concat(props.validate(value)) : errs
        setErrors(errs)
      }}
    />
    {showFirstError(props.errors.get(props.id))}
  </div>)
}

export default ContactForm