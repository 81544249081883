import { useMediaQuery } from 'react-responsive'
import { getAsset } from '../../utils'
import ImageSelector, { Selector } from '../ImageSelector'
import NavButtons from '../NavButtons'

import './DoubleHeight.css'

interface IProps {
	doubleHeight: boolean,
	setDoubleHeight: (doubleHeight: boolean) => void,
	handlePrevScreen: () => void,
	handleNextScreen: () => void
}

function DoubleHeight(props: IProps) {
	const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
	const selectors: Selector[] = [{
		id: "sin_altura",
		title: "SIN ALTURA",
		images: [getAsset("interiors/FORMAT_4-5/livingroom_01.jpg", isMobile)],
		text:
			"Este modelo ofrece el máximo aprovechamiento del espacio,\
			usando toda la superficie del piso superior.\n\
			Todos nuestros salones son amplios de unos 35m2 útiles\
			aprox. , con luz natural y salida a porche exterior.",
		selected: !props.doubleHeight
	}, {
		id: "con_altura",
		title: "CON ALTURA",
		images: [getAsset("interiors/FORMAT_4-5/livingroom_DH_02.jpg", isMobile)],
		text:
			"Salón a doble altura en el que creamos un agradable espacio\
			para albergar un despacho, una zona de lectura o un segundo\
			salón.\n\
			Es una opción versátil que permite ampliar la vivienda a\
			futuro, ocupando también el espacio superior y eliminando la\
			doble altura.",
		selected: props.doubleHeight
	}]

	return (
		<div className="DH-content">
			<div className="horizontal-separator-s"></div>
			<ImageSelector
				options={selectors}
				onSelect={(id: string) => {
					props.setDoubleHeight(id === "con_altura" ? true : false);
				}}
				imgStyle="cover"
			/>
			<div className="horizontal-separator-s"></div>
			<NavButtons handlePrevScreen={props.handlePrevScreen} handleNextScreen={props.handleNextScreen} />
			<div className="horizontal-separator-s" />
		</div>
	)
}

export default DoubleHeight