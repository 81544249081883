import { useState } from 'react'
import { textToFormattedHTML } from '../utils'

import './ImageSelector.css'
import { Slideshow } from './Slideshow'

export interface Selector {
  id: string,
  title: string,
  subtitle?: string,
  images: string[],
  text: string[] | string,
  selected?: boolean
}

interface ImageSelectorProps {
  options: Selector[],
  onSelect: (id: string) => void,
  class?: string,
  imgStyle?: "contain" | "cover",
  JSX?: JSX.Element
}
function ImageSelector(props: ImageSelectorProps) {
  const [selected, setSelected] = useState(props.options.find(o => o.selected) || props.options[0])

  return <article className="IS-box">
    <div className="horizontal-separator-s" />
    <Slideshow
      images={selected.images}
      class={props.class}
      imgStyle={props.imgStyle}
    />
    <div className="horizontal-separator-s" />
    <div className="IS-tabs">
      {props.options.length > 1 && props.options.map((opt, i) =>
        <button
          id={opt.id}
          key={i}
          className={`IS-tab ${selected.id == opt.id && "IS-tab-selected"} ${i % 2 == 0 ? "IS-tab-even" : "IS-tab-odd"}`}
          onClick={() => {
            setSelected(opt);
            props.onSelect(opt.id);
          }}>
          <h1 className="IS-tab-title">{opt.title}</h1>
          <h4 className="IS-tab-subtitle">{opt.subtitle}</h4>
        </button>)}
    </div>
    {props.JSX || null}
    {typeof selected.text === 'string' ?
      <b>{textToFormattedHTML(selected.text)}</b>
      :
      <ul className="IS-ul">
        {selected.text.map(p => <li key={p}>{p}</li>)}
      </ul>
    }
    <div className="horizontal-separator-s" />
  </article>
}

export default ImageSelector