import NavButtons from '../NavButtons'
import './FloorsSelector.css'

interface IProps {
	options: number[];
	floors: number;
	handlePrevScreen: () => void;
	handleNextScreen: () => void;
	setFloors: (floors: number) => void;
}

function FloorsSelector(props: IProps) {
	return (
		<div className="FS-content">
			<h1>Selecciona número de plantas</h1>
			<div className="FS-selector">
				{props.options.map((n, i) =>
					<button
						id={`floors_${i + 1}`}
						key={i}
						className={`FS-floor-button ${props.floors === n && "FS-floor-button-selected"}`}
						onClick={() => props.setFloors(n)}>
						{n}
					</button>
				)}
			</div>
			<br></br>
			<NavButtons handlePrevScreen={props.handlePrevScreen} handleNextScreen={props.handleNextScreen} />
		</div >
	)
}

export default FloorsSelector