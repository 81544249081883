/**
 * Deep compares two objects
 * @param o1 First object
 * @param o2 Second object
 * @returns whether o1 equals o2
 */
export function objectsEqual(o1: any, o2: any): boolean {
  return typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length
    && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
}

/**
 * 
 * @param text text to convert to HTML
 * @returns A set of <p> tags containing the formatted text
 */
export function textToFormattedHTML(text: string) {
  return text.split("\n").map((t, i) => <p key={i}>{t}</p>)
}

/**
 * 
 * @param isMobile User screen has mobile size
 * @returns The path to a file updated for mobile or non-mobile use
 */
export function getAsset(path: string, isMobile = false) {
  return "assets/" + path.replace(/FORMAT_\d+-\d+/, isMobile ? "FORMAT_4-5" : "FORMAT_16-9")
}