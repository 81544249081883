import { RoomDistribution } from "./domain/RoomDistribution"

export interface Page {
  id: string
  position: number
  header: string
}

export type Model = "smart" | "adaptive"

interface ImageI {
  code: string
  name: string
}
interface TextI {
  code: string
  text: string
}
export interface SelectorI {
  name: string
  type: "select" | "checkbox"
  options: OptionI[]
}
export interface OptionI {
  id: string
  name: string
  color?: string
  backgroundImg?: string
  label?: string
  selected?: boolean
  text?: string
}
interface RenderI {
  images: ImageI[]
  texts?: TextI[]
  sideTexts?: TextI[]
}
export interface SurfaceI {
  name: string
  header: string
  selectors: SelectorI[]
  renders: RenderI
}
export class Surface implements SurfaceI {
  name: string
  header: string
  selectors: SelectorI[]
  renders: RenderI
  getSelectedCode: () => string
  withSelected: (selectorId: string, optionId: string) => Surface
  constructor(name: string, header: string, selectors: SelectorI[], renders: RenderI) {
    this.name = name
    this.header = header
    this.selectors = selectors
    this.renders = renders
    this.getSelectedCode = () => {
      return selectors
        .flatMap((s) => s.options.find((opt) => opt.selected === true))
        .map((opt) => opt ? opt.id : "")
        .join("")
        .replace(/^\|+|\|+$/g, ''); //remove |s which are repeated or at the end or beggining of the string
    }
    this.withSelected = (selectorId: string, optionId: string) => {
      const selector = this.selectors.find((s) => s.name === selectorId)
      const option = selector?.options.find((o) => o.id === optionId)
      if (selector === undefined || option === undefined) throw new Error(`Could not find any surface for selector [${selectorId}] and option [${optionId}]`)

      const selectors = this.selectors.slice()
      const options = selector?.options.slice()
      if (selector.type == "select") {
        const oldSelectedIdx = options.findIndex((o) => o.selected === true)
        options[oldSelectedIdx] = { ...options[oldSelectedIdx], selected: false }
        options[options.findIndex((o) => o.id === optionId)] = { ...option, selected: true }
        selectors[selectors.findIndex((s) => s.name === selectorId)] = { ...selector, options: options }
      } else if (selector.type == "checkbox") {
        options[options.findIndex((o) => o.id === optionId)] = { ...option, selected: !option.selected }
        selectors[selectors.findIndex((s) => s.name === selectorId)] = { ...selector, options: options }
      } else {
        throw new Error(`Unexpected selector type [${selector.type}]`)
      }

      return new Surface(this.name, this.header, selectors, this.renders)
    }
  }
}

export interface IExtra {
  id: string,
  name: string,
  description?: string,
  group?: string,
  selected: boolean
}

interface PrototypeParams {
  id: string
  model: Model
  builtArea: number
  usefulArea: number
  doubleHeight: boolean
  distribution: RoomDistribution
  description: string
  price: number
}

export class Prototype {
  id: string
  model: Model
  builtArea: number
  usefulArea: number
  doubleHeight: boolean
  distribution: RoomDistribution
  description: string
  price: number

  totalFloors: () => number
  totalRooms: () => number

  constructor(params: PrototypeParams) {
    this.id = params.id
    this.model = params.model
    this.distribution = params.distribution
    this.doubleHeight = params.doubleHeight
    this.builtArea = params.builtArea
    this.usefulArea = params.usefulArea
    this.description = params.description
    this.price = params.price
    this.totalFloors = () => params.distribution.length
    this.totalRooms = () => params.distribution.reduce((rooms, dist) => rooms + dist.rooms, 0)
  }
}