import { IExtra, Model } from '../../domain'
import { getAsset } from '../../utils'
import Checkbox from '../Checkbox'
import NavButtons from '../NavButtons'
import './Extras.css'

interface IProps {
  model: Model,
  extras: IExtra[];
  setExtras: (extras: IExtra[]) => void,
  handlePrevScreen: () => void,
  handleNextScreen: () => void
}

function Extras(props: IProps) {
  const getExtrasCode = (extras: IExtra[]) => {
    return extras.reduce((acc, cur) => cur.selected ? acc + cur.id : acc + 0, "").split("").join("")
  }

  const getExtrasImgSrc = (extras: IExtra[]) => {
    return getAsset(`extras/${props.model === "adaptive" ? "EcoAdaptive" : "EcoOne"}/${props.model === "adaptive" ? "EA" : "EO"}_${getExtrasCode(extras)}.png`);
  }

  return (
    <div className="extras-content">
      <div className="extras-img-checkboxes-wrapper">
        <img className="extras-img" src={getExtrasImgSrc(props.extras)} alt={"imagen"} />
        <div className="extras-checkboxes">
          <div className="horizontal-separator-m" />
          {props.extras.map((e, i) =>
            <Checkbox
              key={i}
              label={e.name}
              selected={e.selected}
              handleChange={() => {
                let newExtras = [...props.extras.slice(0, i), { ...e, selected: !e.selected }, ...props.extras.slice(i + 1, props.extras.length)]
                if (e.group !== undefined) {
                  newExtras = newExtras.map(e2 => {
                    if (e2.id !== e.id && e2.group === e.group) e2.selected = false;
                    return e2;
                  });
                }
                props.setExtras(newExtras);
              }}></Checkbox>
          )}
          <div className="horizontal-separator-m" />
        </div>
      </div>
      <NavButtons handlePrevScreen={props.handlePrevScreen} handleNextScreen={props.handleNextScreen} />
    </div>
  )
}

export default Extras