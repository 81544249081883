import { createRef } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './Slideshow.css'

interface IProps {
  images: string[],
  class?: string,
  imgStyle?: "contain" | "cover"
}
export function Slideshow(props: IProps) {
  const slideRef = createRef<Slide>()

  const properties = {
    duration: 5000,
    autoplay: false,
    transitionDuration: 200,
    arrows: props.images.length > 1,
    canSwipe: props.images.length > 1,
    infinite: true,
    easing: "ease"
  };
  return (
    <div className={`slide-container ${props.class}`}>
      <Slide ref={slideRef} {...properties}>
        {props.images.map((each, index) => (
          <div key={index} className="each-slide">
            <img className={`lazy ${props.imgStyle === "cover" ? "cover" : "contain"}`} src={each} alt="sample" />
          </div>
        ))}
      </Slide>
    </div>

  );
}