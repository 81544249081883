export interface UTM {
  utm_source?: string,
  utm_medium?: string,
  utm_campaign?: string,
  utm_id?: string,
  utm_term?: string
}

export function getUtms(url: string): UTM {
  const queryParams = new URLSearchParams(url);
  return {
    utm_source: queryParams.get("utm_source") || undefined,
    utm_medium: queryParams.get("utm_medium") || undefined,
    utm_campaign: queryParams.get("utm_campaign") || undefined,
    utm_id: queryParams.get("utm_id") || undefined,
    utm_term: queryParams.get("utm_term") || undefined
  }
}