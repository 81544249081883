import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getAsset } from '../../utils'
import './Intro.css'

interface IProps {
	handleNextScreen: () => void
}

function Intro(props: IProps) {
	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);

	const minSwipeDistance = 50;

	const onTouchStart = (e: any) => {
		setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
		setTouchStart(e.targetTouches[0].clientX)
	}

	const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		if (isLeftSwipe || isRightSwipe) {
			props.handleNextScreen();
		}
	}

	const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
	return (
		<div className="intro-content">
			<div className={`intro-text ${!isMobile && "intro-border-right intro-border-top"}`}
				onTouchStart={onTouchStart}
				onTouchMove={onTouchMove}
				onTouchEnd={onTouchEnd}
				style={isMobile ? { justifyContent: "flex-start", paddingTop: "50px" } : {}}>
				<h1>MyEcoHomes_</h1>
				<p>Personaliza tu proyecto de casa ecológica y obtén presupuesto</p>
				<button id="next_btn" className="intro-button" onClick={() => props.handleNextScreen()}>
					<img className="intro-arrow-right" src={`assets/icons/flecha-verde-150x150.png`} alt="Prototype image" />
				</button>
			</div>
			{!isMobile && <img className="intro-img" src={getAsset("exteriors/FORMAT_4-5/ext_proto_2_sate_blanco_01.jpg")} alt="intro_image" />}
		</div>
	)
}

export default Intro