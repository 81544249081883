import './NavButtons.css'

interface IProps {
  handlePrevScreen: () => void
  handleNextScreen: () => void
}

function NavButtons(props: IProps) {
  return (
    <div className="nav-buttons">
      <button
        id="prev_btn"
        className="app-button"
        onClick={() => props.handlePrevScreen()}>
        &larr; Anterior
      </button>
      <button
        id="next_btn"
        className="app-button"
        onClick={() => props.handleNextScreen()}>
        Siguiente &rarr;
      </button>
    </div>
  );
}

export default NavButtons