import { textToFormattedHTML } from '../utils'
import './Checkbox.css'

interface IProps {
  id?: string,
  label: string,
  description?: string,
  selected: boolean,
  handleChange: () => void,
  /** Disabled prop only works for normal checkbox (not implemented for 'yesNo') */
  disabled?: boolean,
  yesNo?: boolean,
  className?: string,
  labelClass?: string,
  buttonClass?: string
}

function Checkbox(props: IProps) {
  return (<div className={`checkbox-wrapper ${props.className}`}>
    {props.yesNo ? <>
      <div className="checkbox-text">
        <b className={`checkbox-label ${props.labelClass}`}>{props.label}</b>
        {props.description && textToFormattedHTML(props.description)}
      </div>
      <button
        id={`${props.id}_yes`}
        className={`checkbox-yes-no-button ${props.selected && "selected-button"}`}
        onClick={() => props.handleChange()}
        disabled={props.selected}>
        sí
      </button>
      <button
        id={`${props.id}_no`}
        className={`checkbox-yes-no-button ${!props.selected && "selected-button"}`}
        onClick={() => props.handleChange()}
        disabled={!props.selected}>
        no
      </button>
    </> : <>
      <input className={`checkbox-input ${props.buttonClass} ${props.disabled && "checkbox-input-disabled"}`}
        id={props.id}
        type="checkbox"
        checked={props.selected}
        disabled={props.disabled}
        onChange={() => props.handleChange()}
      />
      <div className="checkbox-text">
        <b className={`checkbox-label ${props.labelClass}`}>{props.label}</b>
        {props.description && textToFormattedHTML(props.description)}
      </div>
    </>
    }
  </div>)
}

export default Checkbox