import { Surface } from "../../domain";
import { textToFormattedHTML } from "../../utils";
import Checkbox from "../Checkbox";
import NavButtons from "../NavButtons";
import RadioButton from "../RadioButton";
import './SurfaceSelector.css';

interface IProps {
  surface?: Surface,
  setSurface: (surface: Surface) => void,
  handlePrevScreen: () => void,
  handleNextScreen: () => void
}

function SurfaceSelector(props: IProps) {
  const surface = props.surface
  if (surface === undefined) {
    return <p>No data found for surface screen</p>
  }

  function getSelectedImgSrc(surface: Surface) {
    return surface?.renders.images.find((img) => img.code === surface?.getSelectedCode())?.name
  }

  return (
    <div className="SS-content">
      <div className="horizontal-separator-s" />
      <article className="SS-container">
        <img className="SS-img" src={getSelectedImgSrc(surface)} alt="Foto" />
        <div className="SS-selectors-text-wrapper">
          <div className="SS-selectors-wrapper">
            {surface.selectors.map((s, i) => {
              const cssMode = s.name && (s.options[0].label || s.options[0].text) ? "boxed" : "standard"
              return <div className="SS-selector" key={i}>
                <p className={`SS-selector-title ${cssMode}`}>{s.name}</p>
                <div className={`SS-options-wrapper ${cssMode}`}>
                  {s.options.map((o, i) =>
                    s.type == "select" ?
                      <RadioButton
                        key={i}
                        label={o.label}
                        text={o.text}
                        selected={o.selected || false}
                        handleChange={() => props.setSurface(surface?.withSelected(s.name, o.id))}
                        buttonBackgroundImg={o.backgroundImg}
                        buttonColor={o.color}
                        textLocation={cssMode == "standard" ? "text-top" : "text-right"}
                      /> :
                      <Checkbox
                        key={i}
                        label={o.text || ""}
                        selected={o.selected || false}
                        handleChange={() => props.setSurface(surface?.withSelected(s.name, o.id))}
                        labelClass="SS-checkbox"
                      />
                  )}
                </div>
              </div>
            })}
          </div>
          {surface.renders.sideTexts &&
            <b className="SS-side-text">{textToFormattedHTML(surface.renders.sideTexts.find((t) => t.code === surface.getSelectedCode())?.text || surface.renders.sideTexts.find((t) => t.code === "")?.text || "")}</b>}
        </div>
      </article>
      {surface.renders.texts ?
        <b className="SS-bottom-text">{textToFormattedHTML(surface.renders.texts.find((t) => t.code === surface.getSelectedCode())?.text || surface.renders.texts.find((t) => t.code === "")?.text || "")}</b> :
        <div className="horizontal-separator-m" />}
      <NavButtons handlePrevScreen={props.handlePrevScreen} handleNextScreen={props.handleNextScreen} />
    </div >
  )
}

export default SurfaceSelector